import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Root from '../../components/root';
import PostListing from '../../components/post-listing';
import SideMenu from '../../components/side-menu';
import Pagination from '../../components/pagination-menu';

const AllPostsTemplate = (props) => {
  const { data, pageContext } = props;
  const posts = data.allMarkdownRemark.edges;

  const {
    pageNumber,
    prevPagePath,
    nextPagePath,
    hasPrevPage,
    hasNextPage,
  } = pageContext;

  const fullTitle = `All Posts${pageNumber ? ` - Page ${pageNumber + 1}` : ''}`;
  return (
    <Root>
      <div>
        <Helmet>
          <title>{props.data.site.siteMetadata.title}</title>
          {!pageNumber && <meta name="description" content={props.data.site.siteMetadata.description} />}
          {pageNumber && <meta name="description" content={`Page ${pageNumber + 1} of all posts`} />}
        </Helmet>
        <SideMenu location={props.location} siteMetadata={props.data.site.siteMetadata} />
        <div className="content">
          <div className="content__inner">
            <div className="page">
              <h1 className="page__title">
                {fullTitle}
              </h1>
              <div className="page__content">
                {posts.map(post => (
                  <PostListing data={post} key={post.node.fields.slug} />
                ))}
                <Pagination
                  prevPagePath={prevPagePath}
                  nextPagePath={nextPagePath}
                  hasPrevPage={hasPrevPage}
                  hasNextPage={hasNextPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default AllPostsTemplate;

export const pageQuery = graphql`
  query AllPostsQuery ($postsLimit: Int!, $postsOffset: Int!) {
    site {
      siteMetadata {
        title
        description
        siteTitle
        subtitle
        copyright
        menu {
          label
          path
          link
        }
      }
    }
    allMarkdownRemark(
      limit: $postsLimit,
      skip: $postsOffset,
      filter: { frontmatter: { type: { eq: "post" }, listed: { eq: true }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            type
            title
            date
            description
            featuredImage {
              childImageSharp {
                  fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                  }
              }
            }
          }
        }
      }
    }
  }
`;
